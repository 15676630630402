import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import TermsSwiss from "../../components/prices/TermsSwiss"
import Accordion from "../../components/AccordionBlock"
import { Helmet } from "react-helmet"

import { frCH } from "../../lang/fr-CH"

export default function Features() {
  const location = useLocation()

  let country = "CH"
  let lang = "fr-CH"
  let txt = frCH.prices

  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="DoryGo - Unsere Preise"
        description="So viel kostet unser rundum sorglos Paket"
        lang={lang}
      />
      <Helmet>
      <link
          rel="alternate"
          hreflang="fr-CH"
          href="https://dorygo.com/ch-fr/preise"
        />
        <link
          rel="alternate"
          hreflang="de-DE"
          href="https://dorygo.com/de-de/preise"
        />
        <link
          rel="canonical"
          hreflang="de-CH"
          href="https://dorygo.ch/ch-de/preise"
        />
      </Helmet>

      <TermsSwiss txt={txt} country={country} language={lang}/>
      {/*}
      <Onboarding txt={txt.Onboarding} />
      */}
      <Accordion input ={txt.FAQ}/>  
    </Layout>
  )
}
